// stylelint-disable no-empty-source

$brand-1: red;
$brand-2: red;

$bgColor: #e5e3e5;

:root {
  --x-navbar-padding-y: #{rem(0)};
  --x-table-bg-color: #fff;
}
