// stylelint-disable no-empty-source

@import "_main";

// Gulp looks for variables in the following files in the specified order.
// 1. In the NodeJS package X-Net Rocket SCSS _variables.scss
// 2. In the project.json in the project root
// 3. In the custom_variables.scss
@import "_custom_variables";

//-----------------------------------------------------------------------------
// RESPONSIVE NAVBAR
@media (max-width: rem(991)) {
  .navbar-x-net {
    .nav-item.dropdown {
      text-align: center;
      font-size: 1.1rem;

      &:first-child {
        border-top: rem(1) solid #e6e6e6;
      }

      &:not(:last-child) {
        border-bottom: rem(1) solid #e6e6e6;
      }

      .icon {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }
}

.navbar-x-net {
  .nav-item {
    &.active {
      .nav-link {
        font-weight: bold;
      }

      &::after {
        border: none;
      }
    }

    .nav-link {
      &.fix-text {
        &:focus,
        &:hover {
          color: var(--x-navbar-color);

          .icon {
            fill: var(--x-navbar-color);
          }
        }
      }
    }
  }
}

//------------------------------------------------------------------------------
// LOGIN-AS
.fixed_alert_container {
  background-image: linear-gradient(to bottom, #FCF8E3 0%, #F8EFC0 100%);
  background-repeat: repeat-x;
  border-color: #F5E79E;
  font-size: rem(15);
  padding: rem(9);
}

.svg-inline {
  height: 1.25em;
  vertical-align: -0.225em;
  width: 1.25em;
}

.border-bottom-primary {
  border-bottom: rem(1) solid $primary;
}

.border-2 {
  border-width: rem(2);
}

.border-3 {
  border-width: rem(3);
}

.bg-main {
  background-color: $bgColor;
}
